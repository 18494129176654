
//Landing screen routes
export const MAIN_HOME = '/';
export const MAIN_DATAANNOTATION = '/dataannotation';
export const MAIN_USECASES = '/usecases';
export const MAIN_DATACOLLECTION = '/datacollection';
export const MAIN_PRICING = '/pricing';
export const MAIN_BLOG = '/blog';
export const MAIN_PRIVACY = '/privacy-policy';
export const MAIN_FAQ = '/faq';
